import React, { useState, useEffect } from 'react';
import './collection.css';
import { createhistory } from '../../features/auth/authSlice';
import { getallProducts } from '../../features/product/productSlice';
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../../features/upload/uploadSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate,Link } from 'react-router-dom';
import {resetState, getsingleCollection, updatesingleCollection, deleteSingleCollection,createSingleCollection } from '../../features/collection/collectionSlice';
import { toast } from "react-hot-toast";
import CloseIcon from '@mui/icons-material/Close';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';

const Collection = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [order, setOrder] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [isFeatures,setIsFeatured]=useState(false)
  const [handle, setHandle] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [category, setCategory] = useState("");
  const [collections,setCollections]=useState("")
  const location = useLocation();
  const getCollectionId = location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imgState = useSelector((state) => state.upload.images);
  const collectionState = useSelector((state) => state?.collection?.singlecollection);
  const productState = useSelector((state) => state?.product?.product);
console.log(getCollectionId)
  useEffect(() => {
    setTitle(collectionState?.title);
    setDesc(collectionState?.desc);
    setCategory(collectionState?.category);
    setMetaTitle(collectionState?.metaTitle);
    setMetaDesc(collectionState?.metaDesc);
    setHandle(collectionState?.handle);
    setImage1(collectionState?.banner?.[0] || null);
    setImage2(collectionState?.banner?.[1] || null);
    setOrder(collectionState?.order);
    setIsFeatured(collectionState?.isFeatures)
    setCollections(collectionState?.collections)
  }, [collectionState]);

  useEffect(() => {
    if (getCollectionId !== undefined) {
      dispatch(getsingleCollection({getCollectionId}));
    } else {
      dispatch (resetState())
    }
  }, [dispatch, getCollectionId]);



  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });

  const createCollections = () => {
    if (title === "" || handle === "" || category === "") {
      toast.info("Please Fill All The Required Fields");
    } else {
      const images = [image1, image2];
      if (getCollectionId !== undefined) {
        dispatch(updatesingleCollection({
          id: getCollectionId, data: {
            title: title,
            desc: desc,
            handle: handle,
            metaTitle: metaTitle,
            metaDesc: metaDesc,
            banner: images,
            category: category,
            collections:collections,
            order:order,
            isFeatures:isFeatures,
            createdAt: collectionState?.createdAt,
          }
        }));
        dispatch(createhistory({ name: user?.firstname, title: title, sku: category, productchange: `Updated a Collection`, time: new Date() }));
      } else {
      const images = [image1, image2];
        dispatch(createSingleCollection({
          title: title,
            desc: desc,
            handle: handle,
            metaTitle: metaTitle,
            metaDesc: metaDesc,
            banner: images,
            category: category,
            collections:collections,
            order:order,
            isFeatures:isFeatures,
        }));
        dispatch(createhistory({ name: user?.firstname, title: title, sku: category, productchange: `Created a Collection`, time: new Date() }));
      }
    }
  };

  const deleteImg = (image, setImage) => {
    dispatch(delImg(image.public_id));
    setImage(null);
  };
  const handleImageUpload = (acceptedFiles, setImage) => {
    dispatch(uploadImg(acceptedFiles)).then((result) => {
      const uploadedImage = result?.payload?.[0];
      if (uploadedImage) {
        setImage(uploadedImage);
      }
    });
  };

  const deleteCollection = () => {
    dispatch(deleteSingleCollection({id:getCollectionId}));
    dispatch(createhistory({ name: user?.firstname, title: title, sku: category, productchange: `Deleted a Collection`, time: new Date() }));
    setTimeout(() => {
      navigate("/collection");
    }, 1000);
  };

  useEffect(() => {
    dispatch(getallProducts({ limit: 1000, sort: "order", page: 1, collectionName: collectionState?.title }));
  }, [dispatch, collectionState]);

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(productState?.products || []);
  }, [productState?.products]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
  };

  const handleSave = async () => {
    try {
      const productIds = items.map(item => item._id);
      const response = await axios.post('https://lybrra.onrender.com/api/product/reorder', { productIds });
      if (response.status === 200) {
        alert('Order saved successfully!');
        dispatch(getallProducts({ limit: 1000, sort: "order", page: 1, collectionName: collectionState?.title }));
      } else {
        throw new Error('Failed to save order due to server response');
      }
    } catch (error) {
      console.error('Failed to save order:', error);
      alert('Failed to save order.');
    }
  };

  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };



  return (
    <div className='collection'>
      <div className="collection-head">
        <p>Create Collection</p>
        <div className="right">
          <button onClick={deleteCollection}>Delete</button>
          <button onClick={createCollections}>Save</button>
          {
            getCollectionId!==undefined?
            <button onClick={(e)=>navigate(`/inner/${getCollectionId}`)}>Create</button>
            :
            ""
          }
        </div>
      </div>
      <div className="collection-page">
      <div className="image">
          <p>Collection Image</p>
          {
            image1 === null ?
              <Dropzone onDrop={(acceptedFiles) => handleImageUpload(acceptedFiles, setImage1)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="img-box-in">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop an image here, or click to select an image</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              :
              <div className='second-div'>
                <img src={modifyCloudinaryUrl(image1?.url)} alt={title} />
                <p className="delete" onClick={() => deleteImg(image1, setImage1)}><CloseIcon /></p>
              </div>
          }
        </div>

        <div className="image">
          <p>Collection Banner</p>
          {
            image2 === null ?
              <Dropzone onDrop={(acceptedFiles) => handleImageUpload(acceptedFiles, setImage2)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="img-box-in">
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop an image here, or click to select an image</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              :
              <div className='second-div'>
                <img src={modifyCloudinaryUrl(image2?.url)} alt={title} />
                <p className="delete" onClick={() => deleteImg(image2, setImage2)}><CloseIcon /></p>
              </div>
          }
        </div>
        <div>
          <div className="category">
            <p>Screen Name</p>
            <input type="text" placeholder='Enter Name' onChange={(e) => setCategory(e.target.value)} value={category} />
          </div>
          <div className="category">
            <p>Title</p>
            <input type="text" placeholder='Enter Name' onChange={(e) => setTitle(e.target.value)} value={title} />
          </div>
          <div className="category">
            <p>Description</p>
            <input type="text" placeholder='Enter Description' onChange={(e) => setDesc(e.target.value)} value={desc} />
          </div>
          <div className="handle">
            <p>Handle</p>
            <input type="text" placeholder='Enter Url' onChange={(e) => setHandle(e.target.value)} value={handle} />
          </div>
          <div className="handle">
            <p>Order</p>
            <input type="text" placeholder='Enter Sequence Head' onChange={(e) => setOrder(e.target.value)} value={order} />
          </div>
          <div className="status">
            <p>Featured</p>
            <select name="" id="" onChange={(e) => setIsFeatured(e.target.value)} value={isFeatures}>
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          <div className="metaTitle">
            <p>Meta Title</p>
            <input type="text" placeholder='Enter Meta Title' onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} />
          </div>
          <div className="metaDesc">
            <p>Meta Description</p>
            <textarea placeholder='Enter Meta Description' onChange={(e) => setMetaDesc(e.target.value)} value={metaDesc}></textarea>
          </div>
        </div>
      </div>
<div className="inner-collections">
  {
    collections && collections?.map((item,index)=>{
      return <Link to={`/inner/${getCollectionId}/${item?._id}`}>
      <div className='incard'>
<img src={item?.banner[0]?.url} alt="" />
<p className="name">{item?.title}</p>
      </div>
      </Link>

    })
  }
</div>

      <div className="products">
        <div className="button">
          <button onClick={handleSave}>Update</button>
        </div>
        <div className="header">
          <p>Image</p>
          <p>Title</p>
          <p>Sku</p>
          <p>Stock</p>
          <p>Status</p>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="items">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable key={item._id} draggableId={item._id.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="prdt" onClick={() => navigate(`/addProduct/${item._id}`)}>
                          <img src={modifyCloudinaryUrl(item?.images && item?.images[0]?.url)} alt="" />
                          <p className="title">{item?.title}</p>
                          <p className="title">{item?.sku}</p>
                          <p className="qty">{item?.variants?.reduce((total, item) => total + item?.quantity, 0)}</p>
                          <p>{item?.state}</p>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Collection;
